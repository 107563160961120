import React from 'react';
import { connect, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import Button from '@material-ui/core/Button';

const style = {
  marginRight: 10,
  marginBottom: 10,
  marginTop: 10,
  backgroundColor : '#8DBA38'
}

const RemoteSubmitButton = ({ dispatch }) => {
  
  const message = useSelector(state => state.ui.message)

  return(
    <Button
      type="button"
      variant="contained"
      color="primary"
      style={style}
      disabled={!message}
      onClick={() => {
        dispatch(submit('InvoiceForm'))
      }
      }>
      Terminar
    </Button>
  );
};

export default connect()(RemoteSubmitButton);
