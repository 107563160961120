import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Provider } from "react-redux";

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';

import ReceiptIcon from '@material-ui/icons/Receipt';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Browser from './Browser';
import Orders from './Orders';
import Invoice from './Invoice';
import store from '../../reducers/invoice.reducer';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import RemoteSubmitButton from './Invoice/remote-submit-btn';

import Moment from 'moment';

const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#DCE1EA',
      borderRadius: 1,
    },
  })(StepConnector);

const useColorlibStepIconStyles =  
  makeStyles((theme)=> ({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      color: '#fff'
    },
    completed: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        color: '#fff'
    }
}));
  
const ColorlibStepIcon = (props) => {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
  
    const icons = {
      1: <ReceiptIcon />,
      2: <InsertDriveFileIcon />
    };
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}>
        {icons[String(props.icon)]}
      </div>
    );
}
  
ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};
  
const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '2em',
      width: 700, 
      flexDirection: 'row',
      justifyContent : 'center',
      display: 'flex'
    },
    paper : {
      width: 900,
      paddingTop : 10,
      paddingLeft : 10,
      paddingRight : 10,
      paddingBottom: 10,
      textAlign : "justify"
    },
    button: {
      marginRight: theme.spacing(1),
      marginBottom: 10,
      marginTop: 10,
      backgroundColor : '#8DBA38'
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    steps : {
        backgroundColor : '#ECF0F6',
        borderRadius: 10,
        color: '#0D4269',
        marginBottom: 10,
        fontWeight: 'bold'
    },
    cell : {
      borderWidth : 0,
      display: 'flex',
      justifyContent: 'center',
      direction : 'row'
    },
    buttons : {
      display: 'flex',
      justifyContent: 'center',
      direction : 'row',
      marginTop : 10
    }
}));
  
const getSteps = () => {
    return [
      'Información de la orden', 
      'Datos de facturación'
    ];
}

const Biller = () => {
    console.log("Loading component Biller");

    const [activeStep, setActiveStep] = 
      useState(0);
    const [showContainer, isShowContainer] = 
      useState(false);
    const [order, setOrder] = 
      useState(null);

    const classes = useStyles();
    const steps = getSteps();
    
    const handleNext = () => {  
      setActiveStep(
        (prevActiveStep) =>
          prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(
          (prevActiveStep) => 
            prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        isShowContainer(false);
    };

    const getOrder = (data) => {
      setOrder(data);
      isShowContainer(true);
    };

    const getStepContent = (step) => {
      switch (step) {
          case 0:
            return <Orders data={order}/>;
          case 1:
            const childProps = {
              orderId : order?.orderId, 
              federalTaxId : order?.taxData?.federalTaxId,
              federalTaxIdConfirm : (
                [2,3,4,6,7].includes(order.status)) ?
                order?.taxData?.federalTaxId : null,
              email : order?.taxData?.email,
              platform : order?.platform,
              formPayment: order?.taxData?.payment?.formPayment,
              cfdiUse : order?.taxData?.payment?.cfdiUse,
              taxArrangement : order?.taxData?.payment?.taxArrangement,
              concept : order?.taxData?.concept,
              companyName : "",
              allowEdit : [0,1].includes(order.status)
            };
            return (
                <Invoice 
                  onSubmitSuccess={handleNext}
                  data={childProps} />
            );
          default:
            return '';
      }
    };

    const onNext = (order) => {
      if([5,6,7,8].includes(order?.status))
        return true;
      else return false;
    }

    const reset = ()=> {
      isShowContainer(false);
      setOrder(null);
      handleReset();
    };

    const beginningTime = 
        Moment(new Date(), 'h:mma');
    const endTime = 
        Moment('23:50pm', 'h:mma');
    const isBefore = 
        beginningTime.isBefore(endTime);
    const [serviceEnabled] = 
        useState(isBefore);

    return (
      <div className={classes.root}>
        <Provider store={store}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{fontFamily:"Andale Mono, monospace"}} 
                    align="center"
                    variant="h4">
                    AUTO FACTURADOR
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cell}>
                { !showContainer &&  (
                  <Paper component="form" className={classes.paper}>
                    <Alert>
                      { serviceEnabled && (
                        <div style={{ fontSize : 15 }}>
                          <p><center><b>Siempre pensando en tu comodidad te damos la bienvenida a nuestro servicio de facturación electrónica.</b></center></p>
                          <p>Para obtener tu factura realiza los siguientes pasos:</p>
                          <ol>
                            <li>Ingresa tu número de pedido a facturar.</li>
                            <li>Llena el formulario con tus datos fiscales, verifica que los datos sean correctos.</li>
                            <li>Recibe tu factura vía correo electrónico.</li>
                          </ol>
                          <center><Browser 
                            setOrder={getOrder} 
                            reset={reset} 
                            disabled={serviceEnabled}
                            show={!showContainer} />
                          </center>
                          <p><b>IMPORTANTE:</b></p>
                          <p style={{ fontSize : 16, color : '#02426D' }}><strong>* Tienes 72 horas naturales a partir de la fecha de tu compra para solicitar tu factura, pasado dicho plazo se considerará fuera de tiempo y no procederá tu solicitud.</strong></p>
                          <p>* La fecha de la factura corresponderá al día de su emisión sin importar la fecha de la compra.</p>
                          <p>* Podrás elaborar tu factura de lunes a domingo, en un horario de 00:00 hrs a 22:50 hrs.</p>
                          <p>* Para cualquier duda o aclaración puedes contactar a nuestro equipo en el chat de atención a clientes en <a href="https://www.previta.com.mx" target="_blank" rel="noreferrer">www.previta.com.mx</a>.</p>
                          <p>Para más información, visita nuestra política de facturación.</p>
                        </div>
                      )}
                      { !serviceEnabled && (
                        <div>
                          <p>Por el momento el módulo de facturación electrónica se encuentra fuera de servicio.</p>
                          <p>Te recordamos que podrás elaborar tu factura de lunes a domingo, de 00:00 hrs a 22:50 hrs.</p>
                        </div>
                      )}
                    </Alert>
                  </Paper>
                )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cell}>
                  
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cell}>
                { showContainer &&  (
                    <div>
                      <Stepper 
                          className={classes.steps}
                          alternativeLabel 
                          activeStep={activeStep} 
                          connector={<ColorlibConnector />}>
                          {steps.map((label) => (
                              <Step key={label}>
                                  <StepLabel 
                                      StepIconComponent={ColorlibStepIcon}>
                                      <p className={classes.steps} >{label}</p>
                                  </StepLabel>
                              </Step>
                          ))}
                      </Stepper>
                      <div>
                          {activeStep === steps.length ? (
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell className={classes.cell}>
                                  <Typography className={classes.instructions}>
                                    <Alert severity="success">
                                      Recibirás tu factura electrónica en un periodo de 24 hrs al correo electrónico proporcionado.<br/>
                                      Recuerda que podrás volver a solicitar tu factura con tu número de pedido.<br/><br/>
                                      <p>
                                        ¿Qué puedo hacer en caso de no recibir mi factura pasadas las 24 hrs?
                                        <ul>
                                          <li>Revisa tu bandeja de correos no deseados.</li>
                                          <li>Realiza nuevamente el proceso de solicitud de factura y valida que la cuenta de correo sea correcta.</li>
                                          <li>Solicita tu factura al buzón <u><b>facturas.clientes@previta.com.mx</b></u>.</li>
                                        </ul>
                                      </p>
                                      <center><b>Agradecemos tu preferencia.</b></center>
                                    </Alert>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.cell}>
                                  <Button 
                                    onClick={reset} 
                                    startIcon={<AddCircleOutlineIcon/>}
                                    className={classes.button}>
                                      Realizar otra factura
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          ) : (
                          <div>
                              { getStepContent(activeStep, order) }
                              <div className={classes.buttons}>
                              <Button 
                                  disabled={activeStep === 0} 
                                  onClick={handleBack} 
                                  className={classes.button}>
                                  Atrás
                              </Button>
                              {
                                (activeStep === steps.length - 1) && (
                                  <RemoteSubmitButton />
                                )
                              }
                              {
                                (activeStep !== steps.length - 1) && (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    disabled={onNext(order)}
                                    className={classes.button}>
                                    Siguiente
                                  </Button>
                                )
                              }
                              </div>
                          </div>
                          )}
                      </div>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Provider>
      </div>
    )
}

export default Biller;