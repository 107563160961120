import { types } from '../types/types';

const initialState = {
  isOpenModal: false,
  message: '',
  inputValue: ''
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case types.openModal:
        return {
            ...state,
            isOpenModal: true,
        }

    case types.closeModal:
        return {
            ...state,
            isOpenModal: false,
        }
    
    case types.setMessage:
        return {
            ...state,
            message: action.payload
        }
    
    case types.clearMessage:
        return {
            ...state,
            message: ''
        }
    
    case types.setInputFiscalAddress:
        return {
            ...state,
            inputValue: action.payload
        }

    default:
      return state
  }
}
