import { updateFiscalAddress } from '../services/fiscalAddress.service';
import { types } from '../types/types'
import { closeModal, setMessage } from './uiActions';

export const addNewAddress = (fiscalAddress, raw) => ({
  type: types.newAddress,
  payload: {
    ...fiscalAddress,
    raw: JSON.stringify(raw)
  }
});

export const clearAddress = () => ({
  type: types.deleteAddress
});

export const setFullAddress = (fullAddress) => ({
  type: types.setFullAddress,
  payload: {
    ...fullAddress
  }
});

export const setAddressId = (id) => ({
  type: types.setAddressId,
  payload: id
});

export const startUpdateFiscalAddress = (id, payload) => {
  return async (dispatch) => {
    const resp = await updateFiscalAddress(id, payload);
    if(resp?.status === 204){
      dispatch(setMessage(payload));
      dispatch(closeModal());
      return;
    }
    dispatch(closeModal(payload));
  }
}
