
export const selectSuggestion = async(place_id) => {
   try {
    const url = `https://maps.googleapis.com/maps/api/place/details/json?key=AIzaSyAHxl5dz7cTVs848gGg_o6Tj3hOL0ebTfg&place_id=${place_id}`;
    const resp = await fetch(url, {
        method: 'GET',
        headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json'
        },
    });
    if(resp.status === 200){
        const data = await resp.json();
        return data;
    }
   } catch (error) {
    console.log(error);
   }
}
