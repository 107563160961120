import { React, useEffect, useState, useCallback  } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { InputBase, Table, TableBody, TableCell, 
    TableContainer, TableRow, Button, Link, Modal, 
    List, ListItem } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert'; 
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InfoRounded from '@material-ui/icons/InfoRounded';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";
import { Form, reduxForm, Field } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux'
import stamp from '../../../services/stamp.service';
import { addNewAddress, clearAddress, startUpdateFiscalAddress } from '../../../actions/fiscalAddressActions';
import { openModal, closeModal, clearMessage, setInputFiscalAddress } from '../../../actions/uiActions';
import { getFiscalData } from '../../../actions/googleApiActions';

const useStyles = makeStyles({
    container: {
        minWidth : 800,
        backgroundColor : 'white',
        paddingTop: 10,
        paddingBottom : 10
    },
    loader: {
      position: "fixed", 
      top: "50%", 
      left: "50%", 
      transform: "translate(-50%, -50%)",
    },
    modal1: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        backgroundColor: 'white',
        borderRadius:'10px',
        padding: '10px'
       
    },
    form: {
        
    },
    list: {
        position: 'fixed',
        backgroundColor: 'white',
        zIndex: 1,
        top: '130px',
        borderRadius: '5px',
        maxWidth: '93%',
        width: '93%',
        overflow:'hidden',
        boxShadow: 'rgb(0 0 0 / 35%) 0px 5px 15px'
    },
    width100: {
        width:'100%'
    },
    dFlex:{
        display: 'flex',
        justifyContent:'space-between',
        marginBottom: '15px',
        boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em'
    }
});

const CustomForm = (props) => {
    const { handleSubmit, submitting, data } = props; 

    const disabledHandle = (e) => {
        e.preventDefault();
    };

    const toUpperCase = (e) => {
        e.target.value = 
            ("" + e.target.value)
                .toUpperCase();
    };

    const validateFederalTaxId = (e) => {
        setEnterpriseFederalTaxId(true);
        if(e.target.value.length === 12)
            setEnterpriseFederalTaxId(false);
    };

    const classes = useStyles();
    const [
        isEnterpriseFederalTaxId, 
        setEnterpriseFederalTaxId] = 
            useState(true);

    const renderTextField = (
        { input, label, style, type,
            meta: { touched, error, warning }, 
            ...custom },
    ) => (
        <div>
            <TextField
                label={label}
                style={style}
                type={type}
                {...input}
                {...custom}
            />
            {touched && ((error && 
                <div><br/>
                <Alert
                    style={{width : 400, margin: 'auto'}} 
                    severity="error">{error}</Alert></div>) || 
                (warning && 
                    <div><br/>
                    <Alert
                        style={{width : 400, margin: 'auto'}}
                        severity="error">{warning}</Alert></div>))
            }
        </div>
    );

    const renderSelectField =  (
        { input, label, labelId, value,
            meta: { touched, error, warning }, 
            style, children, ...custom },
    ) => (
        <div>
            <Select
                label={label}
                labelId={labelId}
                style={style}
                children={children}
                value={value}
                {...input}
                {...custom} />
                {touched && ((error && 
                    <div><br/>
                    <Alert 
                        style={{width : 400}} 
                        severity="error">{error}</Alert></div>) || 
                    (warning && 
                        <div><br/>
                        <Alert 
                            style={{width : 400}} 
                            severity="error">{warning}</Alert></div>))}
        </div>
    );

    const helpConcept = "Adjunta al concepto de tu factura una descripción breve. \n" +
        "Por ejemplo: Número de póliza de seguros, nombre del cliente, por mencionar algunos.";

    const isOpenModal = useSelector(state => state.ui.isOpenModal);
    const message = useSelector(state => state.ui.message);

    const dispatch = useDispatch();

    const handleOpenModal = () => {
        dispatch(openModal());
    }

    const handleCloseModal = () => {
        dispatch(closeModal());
    }

    return (
        <Form onSubmit={handleSubmit}>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">
                                <Field
                                    label='Razón Social'
                                    required
                                    value={data?.companyName}
                                    component={renderTextField} 
                                    type="text"
                                    name="companyName"
                                    id="companyName"
                                    disabled={!data.allowEdit}
                                    style={{ 
                                        width : 400,
                                        textTransform : 'uppercase'
                                    }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">
                                <Field 
                                    required
                                    label="RFC"
                                    value={data?.federalTaxId}
                                    component={renderTextField} 
                                    type="text"
                                    name="federalTaxId"
                                    id="federalTaxId"
                                    onCut={disabledHandle.bind(this)}
                                    onCopy={disabledHandle.bind(this)}
                                    onPaste={disabledHandle.bind(this)}
                                    onInput={toUpperCase.bind(this)}
                                    disabled={!data.allowEdit}
                                    style={{ 
                                        width : 400,
                                        textTransform : 'uppercase'
                                    }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">
                                <Field 
                                    required
                                    label="Confirmación de RFC"
                                    value={data?.federalTaxIdConfirm}
                                    component={renderTextField} 
                                    type="text"
                                    name="federalTaxIdConfirm"
                                    id="federalTaxIdConfirm"
                                    onCut={disabledHandle.bind(this)}
                                    onCopy={disabledHandle.bind(this)}
                                    onPaste={disabledHandle.bind(this)}
                                    onBlur={validateFederalTaxId.bind(this)}
                                    onInput={toUpperCase.bind(this)}
                                    disabled={!data.allowEdit}
                                    style={{ 
                                        width : 400,
                                        textTransform : 'uppercase'
                                    }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">
                                <Field
                                    required
                                    label="Email"
                                    value={data.email}
                                    component={renderTextField} 
                                    type="email"
                                    name="email"
                                    id="email"
                                    style={{ width : 400 }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="formPaymentLblId">Forma de pago</InputLabel>
                                    <Field
                                        required
                                        name="formPayment"
                                        id="formPayment"
                                        component={renderSelectField}
                                        value={data?.formPayment}
                                        labelId="formPaymentLblId"
                                        label="Forma de pago"
                                        disabled={!data.allowEdit}
                                        style={{ width : 400 }}>
                                        <MenuItem value="">
                                            <em>-- Selecione --</em>
                                        </MenuItem>
                                        {/* <MenuItem value="03">Transferencia electrónica de fondos</MenuItem> */}
                                        <MenuItem value="04">Tarjeta de crédito</MenuItem>
                                        <MenuItem value="28">Tarjeta de débito</MenuItem>
                                        {/* <MenuItem value="99">Por definir</MenuItem> */}
                                    </Field>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="cfdiuseLblId">Uso del CFDI</InputLabel>
                                    <Field
                                        required
                                        name="cfdiUse"
                                        id="cfdiUse"
                                        component={renderSelectField}
                                        value={data?.cfdiUse}
                                        labelId="cfdiuseLblId"
                                        label="Uso del CFDI"
                                        disabled={!data.allowEdit}
                                        style={{ width : 400}} >
                                        <MenuItem value="">
                                            <em>-- Seleccione --</em>
                                        </MenuItem>
                                        <MenuItem value="G03">Gastos en general</MenuItem>
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="D01">Honorarios médicos, dentales y gastos hospitalarios</MenuItem>
                                        )}
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="D02">Gastos médicos por incapacidad o discapacidad</MenuItem>
                                        )}
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="D07">Primas por seguros de gastos médicos</MenuItem>
                                        )}
                                        <MenuItem value="G01">Adquisición de mercancias</MenuItem>
                                        <MenuItem value="P01">Por definir</MenuItem>
                                    </Field>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="taxArrangementLblId">Régimen fiscal</InputLabel>
                                    <Field
                                        required
                                        name="taxArrangement"
                                        id="taxArrangement"
                                        component={renderSelectField}
                                        value={data?.taxArrangement}
                                        labelId="taxArrangementLblId"
                                        label="Régimen fiscal"
                                        disabled={!data.allowEdit}
                                        style={{ width : 400}} >
                                        <MenuItem value="">
                                            <em>-- Seleccione --</em>
                                        </MenuItem>
                                        { !isEnterpriseFederalTaxId && (
                                            <MenuItem value="601">General de Ley Personas Morales</MenuItem>
                                        )}
                                        { !isEnterpriseFederalTaxId && (
                                            <MenuItem value="603">Personas Morales con Fines no Lucrativos</MenuItem>
                                        )}
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="605">Sueldos y Salarios e Ingresos Asimilados a Salarios</MenuItem>
                                        )}
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="606">Arrendamiento</MenuItem>
                                        )}
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="607">Régimen de Enajenación o Adquisición de Bienes</MenuItem>  
                                        )}
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="608">Demás ingresos</MenuItem>  
                                        )}
                                        <MenuItem value="610">Residentes en el Extranjero sin Establecimiento Permanente en México</MenuItem>
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="611">Ingresos por Dividendos (socios y accionistas)</MenuItem>  
                                        )}
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="612">Personas Físicas con Actividades Empresariales y Profesionales</MenuItem>  
                                        )}
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="614">Ingresos por intereses</MenuItem>  
                                        )}
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="615">Régimen de los ingresos por obtención de premios</MenuItem>  
                                        )}
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="616">Sin obligaciones fiscales</MenuItem>  
                                        )}
                                        { !isEnterpriseFederalTaxId && (
                                            <MenuItem value="620">Sociedades Cooperativas de Producción que optan por diferir sus ingresos</MenuItem>
                                        )}
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="621">Incorporación Fiscal</MenuItem>  
                                        )}
                                        { !isEnterpriseFederalTaxId && (
                                            <MenuItem value="622">Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</MenuItem>
                                        )}
                                        { !isEnterpriseFederalTaxId && (
                                            <MenuItem value="623">Opcional para Grupos de Sociedades</MenuItem>
                                        )}
                                        { !isEnterpriseFederalTaxId && (
                                            <MenuItem value="624">Coordinados</MenuItem>
                                        )}
                                        { isEnterpriseFederalTaxId && (
                                            <MenuItem value="625">Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</MenuItem>  
                                        )}
                                        <MenuItem value="626">Régimen Simplificado de Confianza</MenuItem>
                                    </Field>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableContainer  
                                component={Paper}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            {
                                                !message 
                                                ? <TableCell style={{
                                                    backgroundColor : '#F3CF75',
                                                    border: 'solid 1px #da9613',
                                                    textAlign: 'center'
                                                    }}>
                                                    <IconButton>
                                                        <WarningIcon style={{color:'#ef8f21'}} />
                                                    </IconButton>
                                                    <Link
                                                        style={{color:'black'}}
                                                        onClick={handleOpenModal}>Se requiere la dirección fiscal del contribuyente</Link>
                                                </TableCell> 
                                                :
                                                <TableCell style={{
                                                    backgroundColor : '#d5e8d4',
                                                    border: 'solid 1px #82b366',
                                                    textAlign: 'center',
                                                    
                                               }}>
                                                   <div style={{
                                                       display:'flex',
                                                        flexDirection:'row',
                                                        justifyContent:'center'}}>
                                                        <IconButton>
                                                            <CheckCircleIcon style={{color:'#006600'}} />
                                                        </IconButton>
                                                        <p
                                                            style={{color:'black', maxWidth:'350px', padding:'12px', margin:'0' ,wordBreak:'break-all'}}
                                                            >{message}</p>
                                                   </div>
                                                   <div style={{textAlign:'right', paddingRight:'18px'}}>
                                                        <Link    
                                                            style={{marggin:'0px'}}
                                                            onClick={handleOpenModal}>Editar</Link>
                                                   </div>
                                               </TableCell>
                                            }
                                            
                                            
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">
                                <Grid container style={{marginLeft: 10}}>
                                    <Grid item xs={10} spacing={3}>
                                        <Field
                                            label="Notas adicionales al concepto"
                                            value={data.concept}
                                            component={renderTextField} 
                                            disabled={!data.allowEdit}
                                            type="text"
                                            name="concept"
                                            id="concept"
                                            style={{ width : 350 }} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Tooltip title={helpConcept}>
                                            <IconButton aria-label="delete">
                                                <InfoRounded />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Modal
                    open={isOpenModal}
                    onClose={handleCloseModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                    <div className={classes.modal1}>
                        <h3 style={{color:'#3f51b5'}}>Dirección Fiscal</h3>
                        <PlacesAutocomplete />
                        <FiscalAddressForm />
                    </div>
                </Modal>
            </TableContainer>
            { submitting && (
                <div className={classes.loader}>
                <CircularProgress
                    color="secondary" />
                </div>
            )}
        </Form>
    );
};

const Invoice = (props) => {
    return (
        <CustomForm {...props} />
    )
}

const InvoiceForm = reduxForm({
    form : 'InvoiceForm',
    onSubmit : stamp,
    onSubmitSuccess : (result, dispatch, props) => {
        const { onSubmitSuccess } = props;
        onSubmitSuccess();
    }
})(Invoice);

const PlacesAutocomplete = () => {

    const dispatch = useDispatch();

    const inputValue = useSelector(state => state.ui.inputValue);

    const classes = useStyles();

    const { 
        ready, 
        value, 
        suggestions: { status, data }, 
        setValue, 
        clearSuggestions,
    } = usePlacesAutocomplete({ debounce: 300});

    const handleInput = (e) => {
      setValue(e.target.value);
    };
  

    const handleSelect = ({place_id}) => () => { 
        clearSuggestions();
        getDetails({placeId: place_id}).then((result) =>{
            const fiscalData = getFiscalData(result.address_components);
            dispatch(addNewAddress(fiscalData, result));
            dispatch(setInputFiscalAddress(fiscalData));
        });
    };
    
    useEffect(() => {
        setValue(inputValue, false);
    }, [inputValue, setValue]);

    const renderSuggestions = () =>
      data.map((suggestion) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;
        return (
          <ListItem  
            key={place_id} 
            onClick={handleSelect(suggestion)} 
            style={{borderBottom: 'solid rgba(0, 0, 0, 0.1) 1px', cursor:'pointer'}}>
                <LocationOnIcon style={{color:'rgba(0, 0, 0, 0.3)'}}/>
                &nbsp; 
                <strong>{main_text}</strong> 
                <small>{secondary_text}</small>
          </ListItem>
        );
      });

    return (
      <Paper component="div" className={classes.dFlex}>
        <InputBase
            style={{padding:'15px'}}
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Ingrese la dirección aquí"
            className={classes.width100}
        />
        <IconButton 
            type="button"
            aria-label="search">
            <SearchIcon />
        </IconButton>
        {status === "OK" && <List className={classes.list}>{renderSuggestions()}</List>}
      </Paper>
    );
};

export const FiscalAddressForm = () => {

    const fiscalAddress = useSelector(({fiscalAddress}) => fiscalAddress);

    const dispatch = useDispatch();

    const classes = useStyles();

    const [errors, setErrors] = useState({});

    const [inputValues, setInputValues] = useState({
        country:'',
        route: '',
        street_number:'',
        sublocality_level_1: '',
        locality: '',
        administrative_area_level_1: '',
        postal_code:'',
        city: ''
    });

    const {
        country, 
        route, 
        locality, 
        administrative_area_level_1, 
        street_number, 
        sublocality_level_1, 
        postal_code,
        city
    } = inputValues;
 
    const handleInputChange = ({ target }) => {
        setInputValues({
            ...inputValues,
            [target.name]: target.value,
        }) 
    }

    const handleSubmitForm = () => {
        if(!validate()){
            dispatch(clearMessage());
            return;
        }
        validate();
        if(!inputValues.route || 
            !inputValues.street_number || 
            !inputValues.sublocality_level_1 || 
            !inputValues.locality || 
            !inputValues.administrative_area_level_1 || 
            !inputValues.postal_code){
            return;
        }
        const payload = {
            raw : fiscalAddress.raw,
            route : inputValues.route,
            streetNumber : inputValues.street_number,
            sublocality : inputValues.sublocality_level_1,
            locality : inputValues.locality,
            administrativeArea : inputValues.administrative_area_level_1,
            postalCode : inputValues.postal_code,
            city: inputValues.city || '',
            country: inputValues.country
        }

        dispatch(startUpdateFiscalAddress(fiscalAddress._id, payload));


    }

    const handleCancel = () => {
        setErrors({});
        setInputValues({
            country:'',
            route: '',
            street_number:'',
            street_number_2:'',
            sublocality_level_1: '',
            locality: '',
            administrative_area_level_1: '',
            postal_code:'',
            city: ''
        });
        dispatch(clearAddress());
        dispatch(clearMessage());
    }

    const validate = useCallback(() => {
        let temp = {};
        temp.country = inputValues.country?"":"Este campo es obligatorio";
        temp.route= inputValues.route?"":"Este campo es obligatorio";
        temp.street_number = inputValues.street_number?"":"Este campo es obligatorio";
        temp.sublocality_level_1 = inputValues.sublocality_level_1?"":"Este campo es obligatorio";
        temp.locality = inputValues.locality?"":"Este campo es obligatorio";
        temp.administrative_area_level_1 = inputValues.administrative_area_level_1?"":"Este campo es obligatorio";
        temp.postal_code = inputValues.postal_code?"":"Este campo es obligatorio";
        setErrors({
            ...temp
        });
        return Object.values(temp).every(x => x === "");
    },[inputValues]);

    useEffect(() => {
        const resetInputValues = () => {
            setInputValues({
                ...fiscalAddress
            });
        }  
        resetInputValues()
    }, [
        fiscalAddress,
        setInputValues,
        ]
    );

    useEffect(() => {
        if(!inputValues.country){
            return
        }
        validate(true)
    }, [validate, inputValues.country])

    return(
        <form className={classes.form}>
            <TableContainer style={{padding:'1px', marginBottom:'5px'}}>
                <Table style={{ boxShadow: 'rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 1px'}}>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    label="Calle*"
                                    name='route'
                                    value={route || ''}
                                    className={classes.width100}
                                    autoComplete='off'
                                    onChange={handleInputChange}
                                    onBlur={validate}
                                    error={!!errors.route}
                                    helperText={errors.route}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    label="Número interior*"
                                    name='street_number'
                                    value={street_number || ''}
                                    className={classes.width100}
                                    autoComplete='off'
                                    onChange={handleInputChange}
                                    onBlur={validate}
                                    error={!!errors.street_number}
                                    helperText={errors.street_number}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <TextField 
                                    label="Colonia*"
                                    name='sublocality_level_1'
                                    value={sublocality_level_1 || ''}
                                    className={classes.width100}
                                    autoComplete='off'
                                    onChange={handleInputChange}
                                    onBlur={validate}
                                    error={!!errors.sublocality_level_1}
                                    helperText={errors.sublocality_level_1}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField 
                                    label="Municipio*"
                                    name='locality'
                                    value={locality || ''}
                                    className={classes.width100}
                                    autoComplete='off'
                                    onChange={handleInputChange}
                                    onBlur={validate}
                                    error={!!errors.locality}
                                    helperText={errors.locality}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    label="Ciudad"
                                    name='city'
                                    value={city || ''}
                                    className={classes.width100}
                                    autoComplete='off'
                                    onChange={handleInputChange}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    label="C.P.*"
                                    type="number"
                                    name='postal_code'
                                    value={postal_code || ''}
                                    className={classes.width100}
                                    autoComplete='off'
                                    onChange={handleInputChange}
                                    onBlur={validate}
                                    error={!!errors.postal_code}
                                    helperText={errors.postal_code}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    label="Estado*"
                                    name='administrative_area_level_1'
                                    value={administrative_area_level_1 || ''}
                                    className={classes.width100}
                                    autoComplete='off'
                                    onChange={handleInputChange}
                                    onBlur={validate}
                                    error={!!errors.administrative_area_level_1}
                                    helperText={errors.administrative_area_level_1}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    label="País*"
                                    name='country'
                                    value={country || ''}
                                    className={classes.width100}
                                    autoComplete='off'
                                    onChange={handleInputChange}
                                    onBlur={validate}
                                    error={!!errors.country}
                                    helperText={errors.country}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Alert style={{marginBottom:'10px'}} severity="warning">Verifique que sus datos sean correctos antes de continuar</Alert>
            <TableContainer style={{textAlign: 'right'}}>
                <Button style={{backgroundColor : '#8DBA38', color:'white', marginRight:'10px'}} type='button' onClick={handleSubmitForm}>Subir</Button>
                <Button style={{backgroundColor : '#8DBA38', color:'white'}} onClick={handleCancel} >Cancelar</Button>
            </TableContainer>
        </form>
    )
}


export default connect((state, props) => ({
    initialValues : props.data  
}))(InvoiceForm);