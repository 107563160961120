export const updateFiscalAddress = async (id, payload) => {
    try {
      const url = `/api/orders/${id}/fiscal-address`;
      const resp = await fetch(url,{
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
}