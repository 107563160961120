import { types } from '../types/types';

const initialState = {
  country:'',
  route: '',
  street_number:'',
  street_number_2:'',
  sublocality_level_1: '',
  locality: '',
  administrative_area_level_1: '',
  postal_code:'',
  city:'',
  raw:'',
  _id:''
};

export const fiscalAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case types.newAddress:
      return {
        _id:state._id,
        ...action.payload
    }

    case types.deleteAddress:
        return {
          ...initialState,
          _id:state._id
        }
    
    case types.setAddressId:
        return {
          ...state,
          _id: action.payload
        }

    default:
      return state
  }
}
