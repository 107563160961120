import { types } from '../types/types';

export const openModal = () => ({
    type: types.openModal
  });
  
  export const closeModal = () => ({
    type: types.closeModal
  });
  
  export const clearMessage = () => ({
    type: types.clearMessage
  });
  
  export const setMessage = (message) => {
    return {
        type: types.setMessage,
        payload: `${message.route} ${message.streetNumber}, ${message.sublocality}, ${message.locality}, ${message.postalCode}, ${message.administrativeArea}, ${message.country}`
    }
  };

  export const setInputFiscalAddress = (fiscalData) => {
    return {
        type: types.setInputFiscalAddress,
        payload:(fiscalData.route ? fiscalData.route + ', ' : '') + 
                (fiscalData.street_number ? fiscalData.street_number + ', ' : '') + 
                (fiscalData.sublocality_level_1 ? fiscalData.sublocality_level_1 + ', ' : '') +
                (fiscalData.locality ? fiscalData.locality + ', ' : '') +
                (fiscalData.postal_code ? fiscalData.postal_code + ', ' : '') +
                (fiscalData.administrative_area_level_1 ? fiscalData.administrative_area_level_1 + ', ' : '') +
                (fiscalData.country ? fiscalData.country : ''), 
    }
  };