import React, { useState } from 'react';

import { OrderStatus } from './status.enum';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

const useStyles = makeStyles({
    table: {
      minWidth: 800,
      borderRadius: 10
    },
    order : {
        marginTop : 10
    }
});

const Orders = (props) => {
    console.log("Loading component Order", props);

    const classes = useStyles();
    const { data } = props;
    let component = null;

    const [order, ] = useState(data);
    const [showItems, setShowItems] = 
        useState(false);

    const onClick = () => {
        setShowItems(!showItems);
    }

    if(order){
        component = (
            <TableContainer component={Paper}>
                <Table
                    className={classes.table} 
                    aria-label="spanning table">
                    <TableBody>
                        <TableRow>
                            <TableCell
                                style={{
                                    borderRightStyle : 'solid',
                                    borderRightColor : '#ccc',
                                    borderRightWidth : 1
                                }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="left" colSpan={3}>
                                                <p style={{ color : "gray" }}>Número de pedido</p>
                                            </TableCell>
                                            <TableCell align="right" colSpan={3}>
                                                {order.orderId}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left" colSpan={3}>
                                                <p style={{ color : "gray" }}>Fecha de compra</p>
                                            </TableCell>
                                            <TableCell align="right" colSpan={3}>
                                                {new Date(order.paidDate).toLocaleString()}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left" colSpan={3}>
                                                <p style={{ color : "gray" }}>Estado de la factura</p>
                                            </TableCell>
                                            <TableCell align="right" colSpan={3}>
                                                { OrderStatus[order.status] }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4}
                                                style={{ backgroundColor : '#F7F7F7' }}
                                                align="right">
                                            {
                                                showItems &&
                                                    getItems(order.items, order.currency)
                                            }
                                            {
                                                showItems && (
                                                    <TableContainer component={Paper}>
                                                        <Table style={{
                                                            backgroundColor : '#F3CF75'
                                                        }}>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        Ocultar detalle de productos
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <Button onClick={onClick}>
                                                                            <VisibilityOffOutlinedIcon />
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                )
                                            }
                                            {
                                                !showItems && (
                                                    <TableContainer component={Paper}>
                                                        <Table style={{
                                                            backgroundColor : '#F3CF75'
                                                        }}>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        ({order.items?.length})  Mostrar detalle de productos
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <Button onClick={onClick}>
                                                                            <VisibilityOutlinedIcon/>
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                )
                                            }
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableCell>
                            <TableCell
                                style={{
                                    display        : 'flex',
                                    flexDirection  : 'column',
                                    justifyContent : 'center'
                                }}>
                                <TableContainer 
                                    component={Paper}
                                    style={{ backgroundColor: '#F7F7F7' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={2}>Subtotal</TableCell>
                                                <TableCell align="right">{((order.totals?.subtotal) * 1).toFixed(2)} {order.currency}</TableCell>
                                            </TableRow>
                                            {
                                                order.coupon && order.coupon?.name && (
                                                <TableRow>
                                                    <TableCell colSpan={2}>Cupón</TableCell>
                                                    <TableCell align="right">{order.coupon?.name}</TableCell>
                                                </TableRow>
                                                )
                                            }
                                            {
                                                order.totals && order.totals?.discount > 0 && (
                                                <TableRow>
                                                    <TableCell colSpan={2}>Descuento</TableCell>
                                                    <TableCell align="right">- {(order.totals?.discount * 1).toFixed(2)} {order.currency}</TableCell>
                                                </TableRow>
                                                )
                                            } 
                                            <TableRow>
                                                <TableCell colSpan={2}>I.V.A.</TableCell>
                                                <TableCell align="right">+ {(
                                                    order.totals?.discount ? 
                                                        ((order.totals?.subtotal - order.totals?.discount) * 0.16).toFixed(2) :
                                                        ((order.totals?.subtotal) * 0.16).toFixed(2)
                                                    )} {order.currency}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>Total</TableCell>
                                                <TableCell align="right">{(order.totals?.total * 1.16).toFixed(2)} {order.currency}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else 
        component = <div/>;

    return component;
}

const getItems = (lineItems, currency) => {
    let items = [];
    let index = 0;
    for(let item of lineItems){
        items.push(
            <div key={index++}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <p style={{ color : "gray" }}>Producto</p>
                                </TableCell>
                                <TableCell align="right">{item.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <p style={{ color : "gray" }}>Cantidad</p>
                                </TableCell>
                                <TableCell align="right">{item.price?.quantity}</TableCell>
                            </TableRow>
                            { item.price && item.price?.discount > 0 && (
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <p style={{ color : "gray" }}>Descuento</p>
                                    </TableCell>
                                    <TableCell align="right">{(item.price.discount * 1).toFixed(2)} {currency}</TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <p style={{ color : "gray" }}>Total</p>
                                </TableCell>
                                <TableCell align="right">{(item.price?.total * 1).toFixed(2)} {currency}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <br/>
            </div>
        );
    }
    return items;
}

export default Orders;