
export const getWixOrder = 
    async(orderId) => {
    const params = {
        method: 'GET',
        headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json'
        },
        timeout: 168000
    };

    const response = await fetch(
        `https://facturacion.previta.com.mx/api/wix/orders?orderId=${orderId}`,
        params);
    if(response.status === 200){
        const data = await response.json();
        return data;
    }
    return null;
};

export const stampInvoice = 
    async(orderId, taxData) =>  {
    const params = {
        method : 'PUT',
        headers: { 
            'Content-Type' : 'application/json'
        },
        body : JSON.stringify(taxData),
        timeout: 168000
    };

    const response = await fetch(
        `/api/orders/${orderId}/bills`,
        params);
    
    return response;
};