export const OrderStatus = {
    0: "Pendiente",
    1: "Pendiente",
    2: "Factura en progreso",
    3: "Facturado",
    4: "Factura enviada",
    5: "Error",
    6: "Fuera de tiempo",
    7: "Solicitada",
    8: "Solicitada"
};