export const types = {
    newAddress: '[Address] Add New Address',
    deleteAddress: '[Address] Delete Address',
    setFullAddress: '[Address] Set Full Address',
    setAddressId: '[Address] Get Id',

    openModal: '[UI] Open Modal',
    closeModal: '[UI] Close Modal',
    setMessage: '[UI] Set Message',
    clearMessage: '[UI] Clear Message',
    setInputFiscalAddress: '[UI] Set Input FiscalAddress'

}
  