import { selectSuggestion } from "../services/googleApi.service"
import { addNewAddress } from "./fiscalAddressActions"
import { setInputFiscalAddress } from "./uiActions";

export const startGetSuggestion = (id) => {
    return async (dispatch) => {
        const { result } = await selectSuggestion(id);
        const fiscalData = getFiscalData(result.address_components)
        dispatch(addNewAddress(fiscalData, result));
        dispatch(setInputFiscalAddress(fiscalData));
    }
}

export const getFiscalData = (addressComponents) => {
    const fa = {};
    addressComponents.forEach(address => {
        switch(address.types[0]){
            case 'route': 
                fa.route = address.long_name;
                break;
            case 'locality': 
                fa.locality = address.long_name;
                break;
            case 'administrative_area_level_1': 
                fa.administrative_area_level_1 = address.long_name;
                break; 
            case 'country': 
                fa.country = address.long_name;
                break; 
            case 'sublocality_level_1':
                fa.sublocality_level_1 = address.long_name;
                break;
            case 'postal_code':
                fa.postal_code = address.long_name;
                break;
            case 'street_number':
                fa.street_number = address.long_name;
                break;
            default:
                break;
        }
    });
    return fa;
}