import { SubmissionError } from 'redux-form';
import { stampInvoice } from './order.service';

const accountEmail = 'facturas.clientes@previta.com.mx';

export default (async function submit(values) {
    validate(values);
    const request = {
        companyName : values?.companyName,
        federalTaxId : values?.federalTaxId,
        email : values?.email,
        platform : values?.platform,
        concept : values?.concept,
        payment : {
            form : values?.formPayment,
            cfdiUse : values?.cfdiUse,
            taxArrangement : values?.taxArrangement
        }
    };
  
    const response = 
        await stampInvoice(
          values?.orderId, request);
        
    if(response.status === 204){
      return true;  
    } else {
      const body = await response.json();
      const { code } = body;
      throw new SubmissionError({
        concept : getMessageError(code)
      });
    }
});

const getMessageError = (code) => {
  let message = '';
  switch(code){
    case 1002:
    case 1003:
    case 1004:
    case 1015:
    case 1016:
    case 1017:
    case 1022:
      message = "Lo sentimos, no fue posible atender su solicitud, inténtelo de nuevo más tarde.";
      break;
    case 1005:
    case 1006:
    case 1007:
    case 1008:
    case 1009:
    case 1010:
    case 1011:  
    case 1013:
    case 1014:
    case 1021:
    case 1023:
      message = `Lo sentimos, por el momento no es posible procesar la factura de su número de pedido, favor de solicitar su factura a la siguiente cuenta de correo: ${accountEmail}`;
      break;
    case 1012:
    case 1025:
    case 1026:
      message = "Lo sentimos, es necesario capture correctamente los datos información fiscales de su factura.";
      break;
    case 1024:
      message = `Lo sentimos, el tiempo hábil para generar su factura ha expirado. En caso de cualquier aclaración, favor de enviar un correo a la siguiente cuenta: ${accountEmail}`;
      break;
    default: 
      message = "Ocurrio un error inesperado, favor de intentarlo más tarde.";
  }
  return message;
};

const validate = (values) => {
    const errors = {}
    if(!values.orderId || !values.platform){
      errors.concept = 'Se requiere información adicional para continuar con el proceso de facturacuón, regresa a la pantalla anterior e inténtalo de nuevo'
    }
    if (!values.federalTaxId) {
      errors.federalTaxId = 'Requerido'
    } else if (!/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/i.test(values.federalTaxId)) {
      errors.federalTaxId = 'RFC inválido'
    }
    if(values.federalTaxId !== values.federalTaxIdConfirm){
        errors.federalTaxIdConfirm = 'Favor de revisar la confirmación de tu RFC'
    }
    if (!values.email) {
      errors.email = 'Requerido'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Email inválido'
    }
    if (!values.formPayment) {
      errors.formPayment = 'Requerido'
    }
    if (!values.cfdiUse) {
      errors.cfdiUse = 'Requerido'
    }
    if (!values.cfdiUse) {
      errors.cfdiUse = 'Requerido'
    }
    if (!values.taxArrangement) {
      errors.taxArrangement = 'Requerido'
    }
    if(!values.companyName){
      errors.companyName = 'Requerido'
    }

    if(Object.keys(errors).length > 0)
        throw new SubmissionError(errors);
}