import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import StorefrontIcon from '@material-ui/icons/Storefront';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { getWixOrder } from '../../../services/order.service';
import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { setAddressId } from '../../../actions/fiscalAddressActions';

const platforms = [
    {
      value: 'wix',
      label: 'Previta'
    }
    // {
    //   value: 'rappi',
    //   label: 'RAPPI'
    // }
];

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent : 'center',
      justifyItems : 'center',
      alignItems: 'center',
      width: 700
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
}));

const lbl = '¿Dónde realizaste tu compra?';

const Browser = (props) => {
    console.log("Loading component Browser");

    const classes = useStyles();
    const dispatch = useDispatch();

    //Attributes
    const [anchorEl, setAnchorEl] = 
        useState(null);
    const [platform, setPlatform] = 
        useState({
            key   : lbl, 
            value : 0
        });
    const [showError, setShowError] = 
        useState(false);
    const [msgError, setMsgError] = 
        useState(null);
    // const [showBrowser, setShowBrowser] = 
    //     useState(true);

    let searchRef = React.useRef(null);
    
    //Controller methods

    const onShow = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onHide = () => {
        setAnchorEl(null);
    };

    const onSelect = (event, e) => {
        setPlatform({
            key   : e.label,
            value : e.value
        });
        setAnchorEl(null);
    };

    let { 
        setOrder, 
        reset: resetParent, 
        disabled, 
        show: showBrowser 
    } = props;

    //Input search

    const onKeyDown = (event) => {
        if(event.keyCode === 13)
            event.preventDefault();
    };
    
    const clearErrors = () => {
        setMsgError(null);
        setShowError(false);
    };

    const onClick = async(event) => {
        clearErrors();

        if(!platform || !platform.value || platform.value.length === 0) {
            setMsgError('Selecciona la plataforma por la cual realizaste tu compra');
            setShowError(true);
            event.preventDefault();
            return;
        }

        const orderNumber = 
            searchRef?.current?.value;
        
        if(!orderNumber){
            setMsgError('Ingresa el folio de tu número de pedido');
            setShowError(true);
            event.preventDefault();
            return;
        };
        
        const { value } = platform; 
        
        let order = null;
        switch (value) {
            case 'wix':
                order = 
                    await getWixOrder(
                        orderNumber);
                        dispatch(setAddressId(order.orderId));
                if(!order)
                    return orderNotFound(event);
                order.platform = value;
                reset();
                break;
            default:
                break;
        }  
        setOrder(order);
        showBrowser = false;
    }

    const orderNotFound = (event) => {
        setMsgError('No se encontró la order de compra ingresada, favor de revisar la información de nueva cuenta.');
        setShowError(true);
        event.preventDefault();
    };

    const reset = ()=> {
        setPlatform({
            key   : lbl, 
            value : 0
        });
        if(!showBrowser && searchRef && searchRef.current)
            searchRef.current.value = '';
        showBrowser = true;
        setOrder(null);
        resetParent();
    };

    return (
        <div>
            { disabled && (
                <Paper component="form" className={classes.root}>
                    <Button 
                        aria-controls="simple-menu"
                        aria-haspopup="true" 
                        onClick={onShow}
                        disabled={!showBrowser}>
                        <StorefrontIcon />
                        <span aria-label="search">
                            &nbsp;{platform.key}
                        </span>
                    </Button>
                    <Divider className={classes.divider} orientation="vertical" />
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={onHide} >
                        {
                            platforms.map((option, index) => (
                                <MenuItem 
                                    key={option.value} 
                                    value={platform}
                                    onClick={(event) => onSelect(event, option)} >
                                    {option.label}
                                </MenuItem>
                            ))
                        }
                    </Menu>
                    <InputBase
                        className={classes.input}
                        inputProps={{ 'aria-label': 'search google maps' }}
                        inputRef={searchRef}
                        onKeyDown={onKeyDown}
                        disabled={!showBrowser}
                        placeholder="Busca tu número de pedido"/>
                    <IconButton 
                        type="button"
                        className={classes.iconButton}
                        aria-label="search"
                        onClick={onClick}>
                        { 
                            showBrowser &&
                                <SearchIcon />
                        }
                        {
                            !showBrowser &&
                                <HighlightOffIcon 
                                    style={{color:'red'}} 
                                    onClick={reset} />
                        }
                    </IconButton>
                </Paper>
            )}
            {
                showError && (
                    <div><br/>
                        <Alert
                            style={{width : 400, margin: 'auto'}}
                            severity="error">{msgError}</Alert>
                    </div>
                )
            }
        </div>
    )
}

export default Browser;