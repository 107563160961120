import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import './App.css';
import LogoSrc from './static/images/previta.png';

import Biller from './components/Biller';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import HelpIcon from '@material-ui/icons/HelpOutline';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';

const useStyles = makeStyles((theme)=>({
  menuButton: {
    paddingLeft: '3em'
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const App = () => {
  const classes = useStyles();

  return (
    <div className='App'>
      <AppBar position="sticky">
        <Toolbar style={{backgroundColor:'white'}}>
          <div className={classes.grow} />
          <IconButton 
            size="small"
            edge="start" 
            className={classes.menuButton} 
            color="inherit" aria-label="menu">
            <img src ={LogoSrc} 
            style={{
              height : 60,
              width : 180
            }}
            alt="Previta"/>
          </IconButton>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    <center><b>
                      ¿Qué necesito para facturar?
                    </b></center>
                  </Typography>
                  <ul>
                    <li>Número de pedido</li>
                    <li>RFC (Registro Federal de Contribuyentes)</li>
                    <li>Razón social</li>
                    <li>Correo electrónico</li>
                    <li>Forma de pago</li>
                    <ul>
                      <li>Tarjeta de crédito</li>
                      <li>Tarjeta de débito</li>
                    </ul>
                    <li>Uso de CFDI</li>
                    <ul>
                      <li>Gastos en general</li>
                      <li>Honorarios médicos, dentales y gastos hospitalarios</li>
                    </ul>
                  </ul>
                  <Typography color="inherit">
                    <b><center>
                      ¿Problemas con tu factura?
                    </center></b>
                  </Typography>
                  <p>
                    En caso de no encontrar tu número de pedido, envíanos un correo electrónico al buzón <u><b>facturas.clientes@previta.com.mx</b></u>,
                    no olvides compartir en el correo los puntos anteriormente mencionados. 
                  </p>
                  <p>
                    En caso de requerir un ajuste en los datos de la factura, solicitar la corrección al buzón <u><b>facturas.clientes@previta.com.mx</b></u>.
                  </p>
                  <p>
                    ¿Qué puedo hacer en caso de no recibir mi factura pasadas las 24 hrs?
                    <ul>
                      <li>Revisa tu bandeja de correos no deseados.</li>
                      <li>Realiza nuevamente el proceso de solicitud de factura y valida que la cuenta de correo sea correcta.</li>
                      <li>Solicita tu factura al buzón <u><b>facturas.clientes@previta.com.mx</b></u>.</li>
                    </ul>
                  </p>
                </React.Fragment>
              }>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                  <HelpIcon style={{color:'gray'}} />
                </div>
            </HtmlTooltip>
            <Divider orientation="vertical" flexItem 
              style={{marginLeft:10, marginRight:10}}/>
            <Tooltip title='Buzón de sugerencias'>
              <a href="mailto:facturas.clientes@previta.com.mx">
                <MailOutlineTwoToneIcon style={{color:'gray'}}/>
                </a>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      <header className="App-header">
        <Biller />
      </header>
    </div>
  );
}

export default App;