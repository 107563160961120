import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { reducer as reduxForm } from 'redux-form';
import thunk from 'redux-thunk'
import { fiscalAddressReducer } from './fiscalAddress.reducer';
import { uiReducer } from './ui.reducer';

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const rootReducer = combineReducers({
    form : reduxForm,
    fiscalAddress: fiscalAddressReducer,
    ui: uiReducer
});

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk)),
  )

export default store;